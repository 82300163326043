











































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  DataDictionaryDto,
  FundDtoPagedResultDto,
  SelectListItem,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "FundNoCashDonationList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
  },
  filters: {},
})
export default class FundNoCashDonationList extends Vue {
  queryForm = {};

  donatorType: SelectListItem[] = [];

  created() {
    api.enumService
      .getValues({
        typeName: "DonatorType",
      })
      .then((res) => {
        this.donatorType = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fundNonCashDonation.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "fundNonCashDonationCreate",
    });
  }

  // 查看详情
  jumpDetail(id: number) {
    this.$router.push({
      name: "fundNonCashDonationDetail",
      params: {
        id: id!.toString(),
      },
    });
  }
}
